document.addEventListener('DOMContentLoaded', function(e) {
  'use strict';

  let wrapper = document.querySelector('body > div');

  const observer = new MutationObserver(function(mutations, observer) {
    wrapper.style.height = ''
    wrapper.style.minHeight = ''
  });

  observer.observe(wrapper, {
    attributes: true,
    attributeFilter: ['style']
  });
});
