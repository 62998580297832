import Rails from '@rails/ujs';
Rails.start();

import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-mono/400.css';

import { Chart, BarController, BarElement, CategoryScale, LinearScale, TimeScale, Colors, Tooltip } from 'chart.js';
Chart.register(BarController, BarElement, CategoryScale, LinearScale, TimeScale, Colors, Tooltip);
Chart.defaults.font.family = 'IBM Plex Sans';
import 'chartjs-adapter-luxon';
window.Chart = Chart;

import $ from 'jquery';
window.$ = jQuery;

import ChartsModule from 'javascripts/charts_module.js';
window.drawBarChart = ChartsModule.drawBarChart;

require('datatables.net-dt');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-fixedheader-dt');
require('datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css');

require('javascripts/adsense_fix.js');
require('javascripts/autocomplete.js');
require('javascripts/charts.js');
require('javascripts/datatables.js');

require('stylesheets/application.scss');
require('stylesheets/datatables.scss');

const images = require.context('../images', true);
