'use strict';

const { DateTime } = require('luxon');

let numberFormatter, usdFormatter;

if ('Intl' in window && Intl.NumberFormat) {
  numberFormatter = Intl.NumberFormat('en', {notation: 'compact'});
  usdFormatter = Intl.NumberFormat('en', {style: 'currency', currency: 'USD', notation: 'compact'});
} else {
  numberFormatter = {format: function(v) {return v}};
  usdFormatter = {format: function(v) {return v}};
}

const ChartsModule = {
  drawBarChart(id, chartData, formatterType, dataLabel) {
    if (chartData.data.length === 0) return;

    let ctx = document.querySelector('#' + id);
    let formatter = (formatterType === 'usd' ? usdFormatter : numberFormatter);

    let opts = {
      type: 'bar',
      data: {
        labels: chartData.labels,
        datasets: [{
          label: dataLabel,
          data: chartData.data,
          borderWidth: 2
        }]
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {display: false},
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }

                if (context.parsed.y !== null) {
                  label += formatter.format(context.parsed.y);
                }

                return label;
              }
            }
          }
        },
        scales: {
          x: {
            type: 'time',
            adapters: {
              date: {
                zone: 'utc'
              }
            },
            time: {
              parser: function(t) {
                return DateTime.fromISO(t, {zone: 'utc'}).startOf('quarter').ts;
              },
              unit: 'quarter',
              tooltipFormat: 'Qq yyyy',
              displayFormats: {
                quarter: 'Qq yyyy'
              }
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function(value, index, ticks) {
                return formatter.format(value);
              }
            }
          }
        }
      }
    };

    new Chart(ctx, opts);
  }
};

export default ChartsModule;
