'use strict';

document.addEventListener('DOMContentLoaded', function(e) {
  if ('holdingsChartData' in window) {
    drawBarChart('holdings-chart', holdingsChartData, 'usd', 'Holdings Value');
  }

  if ('cusipChartData' in window) {
    drawBarChart('cusip-value-chart', cusipChartData.value, 'usd', 'Reported Value');
    drawBarChart('cusip-filings-chart', cusipChartData.filings, 'number', 'Total Filings');
  }
});
