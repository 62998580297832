$(function() {
  'use strict';

  const isTouchDevice = 'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  $.extend(true, $.fn.dataTable.defaults, {
    autoWidth: false,
    bLengthChange: false,
    createdRow: (row, data, dataIndex, cells) => {
      $(row).addClass('bg-gray-50 even:bg-white hover:bg-gray-200');
    },
    fixedHeader: (isTouchDevice ? false : {headerOffset: $('nav').outerHeight()}),
    language: {search: 'Filter'},
    order: [],
    pageLength: 50,
    scrollX: isTouchDevice
  });

  const cik = $('[data-cik]').data('cik');
  const cusip = $('[data-cusip]').data('cusip');

  const buttonsDefault = [
    {extend: 'csv', text: 'Download CSV', className: 'mr-4'},
    {extend: 'copy', text: 'Copy to Clipboard'}
  ];

  let symbolOptions = {
    createdCell: addClassToTdNotTh('truncate')
  };

  let issuerNameOptions = {
    createdCell: addClassToTdNotTh('truncate')
  };

  let classOptions = {
    createdCell: addClassToTdNotTh('truncate')
  };

  let centeredTextOptions = {
    createdCell: addClassToTdNotTh('text-center')
  };

  let numberOptions = {
    createdCell: addClassToTdNotTh('text-right'),
    orderSequence: ['desc', 'asc'],
    render: $.fn.dataTable.render.number(',', '.')
  };

  let holdingsPctOptions = {
    createdCell: addClassToTdNotTh('text-right'),
    orderSequence: ['desc', 'asc'],
    render: function(pct, type, row) {
      if (pct == null) return '';

      if (type === 'display') {
        let digits = pct >= 10 ? 0 : 1;
        return pct.toFixed(digits) + '%';
      }

      return pct;
    }
  };

  let cusipOptions = {
    createdCell: addClassToTdNotTh('text-center'),
    render: function(cusip, type, row) {
      if (type === 'display') {
        return `<a href="/manager/${cik}/cusip/${cusip}">${cusip}</a>`;
      }

      return cusip;
    }
  };

  let otherManagerOptions = {
    createdCell: addClassToTdNotTh('text-center truncate')
  };

  let comparisonPctOptions = {
    createdCell: addClassToTdNotTh('text-right truncate'),
    orderSequence: ['desc', 'asc'],
    render: function(pct, type, row) {
      if (type === 'display') {
        return pct == null ? 'NEW' : Math.round(pct) + '%';
      } else if (type === 'sort') {
        return pct == null ? Infinity : pct;
      }

      return pct;
    }
  };

  let dateFiledOptions = {
    createdCell: addClassToTdNotTh('text-right'),
    render: function(data, type, row) {
      if (type === 'display') {
        return mdy(data);
      }

      return data;
    }
  };

  let reportDateOptions = {
    createdCell: addClassToTdNotTh('text-right'),
    render: function(data, type, row) {
      if (type === 'display') {
        return `<a href="/13f/${data[1]}">${mdy(data[0])}</a>`;
      }

      return data[0];
    }
  };

  let yearQuarterOptions = {
    createdCell: addClassToTdNotTh('text-center'),
    render: function(data, type, row) {
      let label = `${data[0]} Q${data[1]}`;

      if (type === 'display') {
        return `<a href="/cusip/${cusip}/${data[0]}/${data[1]}">${label}</a>`;
      }

      return label;
    }
  };

  let managerOptions = {
    createdCell: addClassToTdNotTh('truncate'),
    render: function(data, type, row) {
      if (type === 'display') {
        return `<a href="/manager/${data[1]}/cusip/${data[2]}">${data[0]}</a>`;
      }

      return data[0];
    }
  };

  function mdy(dateString) {
    let parts = dateString.split('-');
    return [+parts[1], +parts[2], +parts[0]].join('/');
  }

  function addClassToTdNotTh(className) {
    return (td, cellData, rowData, rowIndex, colIndex) => {
      let $td = $(td);
      $td.addClass(className);

      if (className === 'truncate') {
        $td.attr('title', $td.text());
        $td.addClass('group hover:overflow-visible');
        $td.wrapInner('<span class="group-hover:bg-white group-hover:relative group-hover:px-1 group-hover:-ml-1 group-hover:rounded group-hover:shadow"></span>');
      }
    }
  }

  function errorHandler(xhr, status, error) {
    alert('Sorry, something went wrong. You could try reloading the page');
  }

  $('#filingAggregated').DataTable({
    ajax: {
      cache: true,
      error: errorHandler,
      url: $('#filingAggregated').data('url')
    },
    buttons: buttonsDefault,
    columns: [
      symbolOptions,
      issuerNameOptions,
      classOptions,
      cusipOptions,
      numberOptions,
      holdingsPctOptions,
      numberOptions,
      numberOptions,
      centeredTextOptions,
    ],
    dom: 'l<"w-52"f>rtpiB'
  });

  $('#filingDetailed').DataTable({
    ajax: {
      cache: true,
      error: errorHandler,
      url: $('#filingDetailed').data('url')
    },
    buttons: buttonsDefault,
    columns: [
      symbolOptions,
      issuerNameOptions,
      classOptions,
      cusipOptions,
      numberOptions,
      holdingsPctOptions,
      numberOptions,
      numberOptions,
      centeredTextOptions,
      centeredTextOptions,
      otherManagerOptions,
      numberOptions,
      numberOptions,
      numberOptions
    ],
    columnDefs: [
      {targets: [11, 12, 13], width: '8rem', className: 'truncate'}
    ],
    dom: 'l<"w-52"f>rtpiB'
  });

  $('#filingComparison').DataTable({
    ajax: {
      cache: true,
      error: errorHandler,
      url: $('#filingComparison').data('url')
    },
    buttons: buttonsDefault,
    columns: [
      symbolOptions,
      issuerNameOptions,
      classOptions,
      cusipOptions,
      centeredTextOptions,
      numberOptions,
      numberOptions,
      numberOptions,
      comparisonPctOptions,
      numberOptions,
      numberOptions,
      numberOptions,
      comparisonPctOptions
    ],
    createdRow: (row, data, dataIndex, cells) => {
      let bgClass;

      if (data[8] === null) {
        bgClass = 'bg-green-100 hover:bg-green-200';
      } else if (data[8] === -100) {
        bgClass = 'bg-red-100 hover:bg-red-200';
      } else {
        bgClass = 'bg-gray-50 even:bg-white hover:bg-gray-200';
      }

      $(row).addClass(bgClass);
    },
    dom: 'l<"w-52"f>rtpiB'
  });

  $('#managerCusipHoldings').DataTable({
    ajax: {
      cache: true,
      dataSrc: function(json) {
        let chartData = {
          shares: {labels: [], data: []},
          value: {labels: [], data: []}
        };

        json.data.filter(a => (a[4] === null)).reverse().forEach(a => {
          chartData.shares.labels.push(a[0][0]);
          chartData.value.labels.push(a[0][0]);
          chartData.shares.data.push(a[3]);
          chartData.value.data.push(a[1] * 1000);
        });

        drawBarChart('manager-cusip-shares-chart', chartData.shares, 'number', 'Shares');
        drawBarChart('manager-cusip-value-chart', chartData.value, 'usd', 'Reported Value');

        return json.data;
      },
      error: errorHandler,
      url: $('#managerCusipHoldings').data('url')
    },
    buttons: buttonsDefault,
    columns: [
      reportDateOptions,
      numberOptions,
      holdingsPctOptions,
      numberOptions,
      centeredTextOptions,
      dateFiledOptions,
      yearQuarterOptions
    ],
    dom: 'l<"w-52"f>rtpiB'
  });

  $('#allCusipHoldings').DataTable({
    ajax: {
      cache: true,
      error: errorHandler,
      url: $('#allCusipHoldings').data('url')
    },
    buttons: buttonsDefault,
    columns: [
      managerOptions,
      reportDateOptions,
      numberOptions,
      numberOptions,
      centeredTextOptions
    ],
    dom: 'l<"w-52"f>rtpiB'
  });

  $('#managerFilings').DataTable({
    columnDefs: [
      {targets: [1, 2], orderSequence: ['desc', 'asc']}
    ],
    searching: false
  });

  let cusipSharesOptions = {
    createdCell: addClassToTdNotTh('text-right'),
    orderSequence: ['desc', 'asc'],
    render: function(num, type, row) {
      if (num == null && type === 'display') {
        return 'not available';
      } else if (type === 'sort') {
        return num == null ? 0.5 : num;
      }

      return $.fn.dataTable.render.number(',').display(num);
    }
  };

  let sharesDiffOptions = {
    createdCell: addClassToTdNotTh('text-right'),
    orderSequence: ['desc', 'asc'],
    render: function(num, type, row) {
      if (type === 'sort') {
        return num == null ? 0.5 : num;
      }

      return $.fn.dataTable.render.number(',').display(num);
    }
  };

  let sharesComparisonPctOptions = {
    createdCell: addClassToTdNotTh('text-right truncate'),
    orderSequence: ['desc', 'asc'],
    render: function(pct, type, row) {
      let unknownChange = (row[1] == null || row[2] == null);

      if (type === 'display') {
        if (unknownChange) {
          return '';
        } else if (pct == null) {
          return 'NEW';
        } else {
          return Math.round(pct) + '%';
        }
      }

      if (type === 'sort') {
        if (unknownChange) {
          return 1e-9;
        } else if (pct == null) {
          return Infinity;
        } else {
          return pct;
        }
      }

      return pct;
    }
  };

  $('#cusipComparison').DataTable({
    ajax: {
      cache: true,
      error: errorHandler,
      url: $('#cusipComparison').data('url')
    },
    buttons: buttonsDefault,
    columns: [
      managerOptions,
      cusipSharesOptions,
      cusipSharesOptions,
      sharesDiffOptions,
      sharesComparisonPctOptions
    ],
    createdRow: (row, data, dataIndex, cells) => {
      let bgClass;

      if (data[4] === null && data[1] === 0) {
        bgClass = 'bg-green-100 hover:bg-green-200';
      } else if (data[4] === -100) {
        bgClass = 'bg-red-100 hover:bg-red-200';
      } else {
        bgClass = 'bg-gray-50 even:bg-white hover:bg-gray-200';
      }

      $(row).addClass(bgClass);
    },
    dom: 'l<"w-52"f>rtpiB'
  });

  $('#cikFormDs').DataTable({
    columnDefs: [
      {targets: [2], orderSequence: ['desc', 'asc']}
    ],
    searching: false
  });

  $('#cikFormDsDiff').DataTable({
    columnDefs: [
      {targets: [2, 3], orderSequence: ['desc', 'asc']}
    ],
    searching: false
  });

  let usdFormatter = Intl.NumberFormat('en', {style: 'currency', currency: 'USD', maximumFractionDigits: 0});

  let currencyOptions = {
    createdCell: addClassToTdNotTh('text-right'),
    orderSequence: ['desc', 'asc'],
    render: function(data, type, row, meta) {
      if (!data) return '';
      if (type === 'display') {
        return usdFormatter.format(data);
      }
      return data;
    }
  };

  let dateOptions = {
    createdCell: addClassToTdNotTh('text-right'),
    orderSequence: ['desc', 'asc'],
    render: function(data, type, row, meta) {
      if (type === 'display') {
        let [y, m, d] = data.split('-');
        return [Number(m), Number(d), Number(y)].join('/');
      }
      return data;
    }
  };

  let formDTable = $('#formDList').DataTable({
    serverSide: true,
    ajax: {
      cache: false,
      error: errorHandler,
      url: $('#formDList').data('url'),
      data: function (d) {
        let params = {
          q: $('#formDFilter').val(),
          types: $('input[name="filerType"]:checked').map(function() {return this.value}).get(),
          securities: $('input[name="securityType"]:checked').map(function() {return this.value}).get(),
          entities: $('input[name="entityType"]:checked').map(function() {return this.value}).get(),
          location: $('#location').val(),
          forms: $('input[name="formType"]:checked').map(function() {return this.value}).get(),
          from: $('#fromDate').val(),
          to: $('#toDate').val()
        };

        updateFormDUrl(params);

        return $.extend({}, d, params);
      }
    },
    buttons: buttonsDefault,
    columns: [
      {
        data: 'name',
        createdCell: addClassToTdNotTh('truncate'),
        render: function(data, type, row) {
          if (type === 'display') {
            return `<a href="/form-d/${data[1]}">${data[0]}</a>`;
          }
          return data[0];
        }
      },
      $.extend({}, {data: 'date'}, dateOptions),
      {data: 'filer_type', createdCell: addClassToTdNotTh('truncate')},
      $.extend({}, {data: 'total_amount_sold_diff'}, currencyOptions),
      $.extend({}, {data: 'total_amount_sold'}, currencyOptions),
      {
        data: 'form_type',
        createdCell: addClassToTdNotTh('text-center'),
        orderable: false
      },
      {
        data: 'types_of_securities',
        createdCell: addClassToTdNotTh('truncate'),
        orderable: false
      },
      {
        data: 'related_people',
        createdCell: addClassToTdNotTh('truncate'),
        orderable: false
      },
      {
        data: 'location',
        createdCell: addClassToTdNotTh('truncate'),
        orderable: false,
        render: function(data, type, row) {
          if (type === 'display') {
            return `<a href="#" class="form-d-location">${data}</a>`;
          }
          return data;
        }
      },
      {
        data: 'entity_type',
        createdCell: addClassToTdNotTh('truncate')
      }
    ],
    dom: 'lrtpiB',
    order: [[1, 'desc']],
    pageLength: 100,
    searching: false
  });

  let formDTimeout;

  $('#formDFilter, #location').on('input', function() {
    clearTimeout(formDTimeout);
    formDTimeout = setTimeout(function() {
      formDTable.ajax.reload()
    }, 250);
  });

  $('input[name="filerType"], input[name="securityType"], input[name="entityType"], input[name="formType"], #fromDate, #toDate').on('change', function() {
    formDTable.ajax.reload();
  });

  $('input[name="filerType"], input[name="securityType"], input[name="entityType"], input[name="formType"]').on('change', function(e) {
    let types = $(`input[name="${e.target.name}"]:checked + label`).map(function() {return this.innerHTML}).get().join(', ');
    $(`.filtered-${e.target.name}`).html(types);
  });

  $('#formDList').on('click', '.form-d-location', function(e) {
    e.preventDefault();
    $('#location').val(e.target.innerHTML);
    formDTable.ajax.reload();
  });

  function updateFormDUrl(params) {
    let urlParams = new URLSearchParams();

    ['q', 'from', 'to', 'types', 'securities', 'entities', 'forms', 'location'].forEach(p => {
      if (params[p] && params[p].length) urlParams.set(p, params[p]);
    });

    let path = (urlParams.toString() ? '?' + decodeURIComponent(urlParams.toString()) : location.pathname);
    window.history.replaceState({}, '', path);
  }
});
